@danger-orange: #ff4e00;
@danger-light-orange: #ffede5;

@warning-yellow: #ffa000;
@warning-light-yellow: #fff5e5;

@info-blue: #009fda;
@info-light-blue: #e5f5fb;

@success-green: #2baf2b;
@success-light-green: #e9f7e9;

@primary-green: @success-green;
@secondary-blue: @info-blue;
@active-blue: #047fad;

@lightest-gray-background: #f6f6f6;
@light-gray-background: #eeeeee;
@darkest-background: #263340;
@dark-background: #37424d;

@lightest-gray-border: #f6f6f6;
@light-gray-border: #eeeeee;
@dark-gray-border: #dedede;
@darkest-gray-border: #cccccc;

@focused-input-border: @info-blue;
@invalid-input-border: @danger-orange;

@invalid-input-message: @danger-orange;

@light-gray-text: #cccccc;
@gray-text: #999999;
@default-text: #666666;
@dark-text: #222222;
@darkest-text: #000000;

@default-spacing: 24px;
@container-width: 1200px;
